import hubs from "./hubs"
import weblink from "./weblink"
import dna from "./dna"
import dna3d from "./dna3d"
import gsheet from "./gsheet"
export default {
    "hubs":hubs,
    "weblink":weblink,
    "gsheet":gsheet,
    "dna3d":dna3d,
    "dna":dna
}
