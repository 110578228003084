function parseLine(l) {
    var a = l.split(",")
    return {
        "i": parseInt(a[0]),
        "x": parseFloat(a[1]),
        "y": parseFloat(a[2]),
        "z": parseFloat(a[3])
    }
}
function distance(a,b) {
    var dx = a.x-b.x
    var dy = a.y-b.y
    var dz = a.z-b.z
    return Math.sqrt(dx*dx + dy*dy + dz*dz)
}
export default function() {
    var binsize
    var genome
    var title
    var chrs = []
    var chr2idx = {}
    var nodesize = 1
    var chart = function(d) {

    }
    chart.init = function(d) {

    }
    chart.read = function(d) {
        var lines = d.split("\n")
        var a = lines[0].split("\t")
        if (a[0] == "TITLE") {
            title = a[1]
        }
        a = lines[1].split("\t")
        if (a[0] == "GENOME") {
            genome = a[1]
        }
        a = lines[2].split("\t")
        if (a[0] == "BINSIZE") {
            binsize = a[1].replace(/,/g, "")
        }
        var j = -1
        for (var i = 3; i < lines.length; i++) {
            a = lines[i].split("\t")
            if (a[0] == "CHR") {
                chrs.push({
                    name: a[1],
                    data: []
                })
                j++;
                chr2idx[a[1]] = j
            } else if (j >= 0) {
                chrs[j].data.push(parseLine(lines[i]))
            }
        }
        /* Guess Node Size */

        return chart
    }
    chart.guessNodeSize = function() {
        var s = 0.0
        var n = 0
        chrs.forEach(function(chr){
            var l = chr.data.length
            for (var i=0;i<l-1;i++){
                var d = distance(chr.data[i+1],chr.data[i])
                if (d > 0) {
                    s += d
                    n+=1
                }
            }
        })
        return s/n
    }
    chart.ThreeDMol = function() {
        var mols = []
        var mols = chrs.map(function(chr) {
            var mol = []
            chr.data.forEach(function(d, i) {
                mol.push({
                    "chr":chr.name,
                    "index": d.i,
                    "x": d.x,
                    "y": d.y,
                    "z": d.z,
                    "id": d.i,
                })
            })
            var l = mol.length
            for (var i = 0; i < l - 1; i++) {
                mol[i].bonds = [mol[i + 1].index]
                mol[i].bondOrder = [1]
            }
            return mol
        })
        return mols

    }
    chart.genome = function(_) {
        return arguments.length ? (genome = _, chart) : genome;
    }
    chart.title = function(_) {
        return arguments.length ? (title = _, chart) : title;
    }
    chart.binsize = function(_) {
        return arguments.length ? (binsize = _, chart) : binsize;
    }
    chart.nodesize = function(_) {
        return arguments.length ? (nodesize = _, chart) : nodesize;
    }


    return chart
}
