import {
    overlap
} from "./funcs"
import * as d3 from "d3"
import * as $ from "jquery"

import nucle3dParse from "./nucle3dParse"
import webglSupport from "./webglSupport"
if (!("$" in window)) window.$ = $
const $3Dmol= require("3dmol")

var rainbow = d3.scaleOrdinal(d3.schemeCategory10)
//TODO: Clean Code for Coordinates Translatr
var render = function(el, chrs, ratio, nodesize) {
    let element = $(el.node());
    let config = {};
    //var viewer = $3Dmol.createViewer(element, config);
    var viewer = $3Dmol.createViewer(element, config);
    viewer.clear()
    viewer.setBackgroundColor(0xfbfbfbff);
    var chr2idx = {}
    var chr2model = {}
    chrs.forEach(function(d, i) {
        var m = viewer.addModel();
        m.addAtoms(d);
        chr2idx[d[0].chr] = i;
        chr2model[d[0].chr] = m;
        m.setStyle({}, {
           line: {
                singleBonds: true,
                radius: 0.75,
                linewidth: 1,
                opacity: 0.35,
                alpha: 0.35,
                color: rainbow(i)
            },
        });
        m.setClickable({},true,function(d){
            console.log(m,d)
        })
        m.setHoverable({},true,function(d){
            console.log("hover",d)
            m.setStyle({},{
                stick: {
                    singleBonds: true,
                    radius: 0.55 * nodesize/5, 
                    color:"green"
                },
            })
            viewer.render()

        },function(d){
            m.setStyle({},{line:{
                singleBonds: true,
                radius: 0.75,
                linewidth: 1,
                opacity: 0.35,
                alpha: 0.35,
                color: rainbow(i)
                }
            })
            viewer.render()
            console.log("unhover",d)
        })
    })
    viewer.zoomTo();
    if (ratio<0.95) {
        viewer.zoom(ratio)
    } else {
        viewer.zoom(0.95);
    }
    /*
    if (height<width) {
        viewer.zoom(height/width)
    }
    */
    viewer.render();
    var r = {
        data: chrs,
        idx: chr2idx,
        viewer: viewer,
        model: chr2model
    }
    return r

};
//　Add Parameters for Rendering??
//
function highlight(o, regions, step, nodesize) {
    var hset = {}
    var chrs = {};
    if (typeof step == "string") {
        step=parseInt(step)
    }
    // regions contain color???
    regions.forEach(function(d) {
        var atoms = o.data[o.idx[d.chr]]
        var start = Math.floor(d.start / step)
        var end = Math.floor(d.end / step) + 1
        for (var i = start; i < end; i++) {
            if (atoms[i] && "id" in atoms[i]) {
                hset[atoms[i].id] = 1
            }
        }
        if (!(d.chr in chrs)) {
            chrs[d.chr] = true;
        }
    })
    // TODO: Better Highlight API 
    //       Both Current Region and Brush Regions
    var _highlight = function(atom) {
        if ("index" in atom) {
            if (hset[atom.id]) {
                return rainbow(o.idx[atom.chr]) 
                // choose to change to highlight color2 
                // choose to change to any color schema
            } else {
                return "#BBB"
            }
        }
    };
    Object.keys(o.model).forEach(function(d) {
        o.model[d].setStyle({}, {
            line: {
                singleBonds: true,
                radius: 0.75,
                linewidth: 2,
                opacity: 0.15,
                color: rainbow(o.idx[d])
            }
        })
    })

    Object.keys(chrs).forEach(function(d) {
        o.model[d].setStyle({}, {
            stick: {
                singleBonds: true,
                radius: 0.55 * nodesize/5, 
                colorfunc: _highlight
            },
            line: {
                opacity:0.1,
            }
        })
    })
    o.viewer.render()
}


export default function() {
    var uri
    var regions //is highlight regions
    var height
    var width
    var callback
    var o //object for responds
    var hasWebGL
    var binsize = 250000 //250k default
    var nodesize = null 
    var chart = function(el) {
        if (typeof hasWebGL == "undefined") {
            hasWebGL = webglSupport()
        }
        if (hasWebGL) {
            el.selectAll(".genome").remove()
            //TODO ADD CANVAS Add Legend
            //     ADD STYLE CONTROL (Resolution Data Points, Chromosome Color) 
            var g = el.append("div").classed("genome", true)
                .style("padding-left","10px")
                .style("padding-right","10px")
            .attr("align","center")
            var divh = g.append("div").classed("title", true)
                .append("div").classed("titleComponent", true)
                .style("height","30px")
                .style("background-color","aliceblue")
            //ADD MORE INFORMATTION ??
            var divm = g.append("div")
                .classed("model", true)
                .style("min-width","300px")
                .style("width",width+"px")
                .style("min-height", "300px")
                .style("height",height+"px")
                .style("position", "relative")
            d3.text(uri, {
                //credentials: "include" //TODO
            }).then(function(d) {
                var n3d 
                try {
                    n3d = nucle3dParse(d) //add Try and Catch error
                    binsize = n3d.binsize()
                    if (nodesize == null) {
                        nodesize = n3d.guessNodeSize()
                    }
                    //TO IMPROVE
                    divh.append("span").classed("panel-title",true).text(n3d.title())
                    divh.append("span").style("float","right").style("padding-left","5px").style("padding-right","5px").text(d3.format(".2s")(binsize))
                    divh.append("span").style("float","right").text(n3d.genome())
                    o = render(divm, n3d.ThreeDMol(),width/height, nodesize)
                    if (regions.length > 0) {
                        highlight(o, regions, binsize, nodesize)
                        }
                } catch(e) {
                    callback({"error":"Parse Error"})
                }
                callback(null)
            }).catch(function(e){
                callback({"error":"Fetch URL Error"})
            })
        } else {
            el.html("Your browser or device may not support WebGL")
            callback({"error":"not support WebGL"})
        }
    }
    chart.highlight = function(r) {
        regions = r
        if (hasWebGL) {

            highlight(o, r, binsize,nodesize) //add highlight options;
        }
    }
    chart.width = function(_) {
        return arguments.length ? (width = _, chart) : width;
    }
    chart.height = function(_) {
        return arguments.length ? (height = _, chart) : height;
    }
    chart.uri = function(_) {
        return arguments.length ? (uri = _, chart) : uri;
    }
    chart.binsize = function(_) {
        return arguments.length ? (binsize = _, chart) : binsize;
    }
    chart.regions = function(_) {
        return arguments.length ? (regions = _, chart) : regions;
    }
    chart.callback = function(_) {
        return arguments.length ? (callback = _, chart) : callback;
    }
    return chart
}
